.pax-card {
  margin-right: 2px;
}
.booking-ddl {
  border: "1px solid #dc3545";
  box-shadow: "none";
}
/* Optional: Add these styles to your CSS file for better pagination appearance */
.pagination .page-link {
  color: #333;
  border-color: #dee2e6;
}

.pagination .page-item.active .page-link {
  background-color: #007bff;
  border-color: #007bff;
  color: white;
}

.pagination .page-item.disabled .page-link {
  color: #6c757d;
  pointer-events: none;
  background-color: #fff;
  border-color: #dee2e6;
}

.pagination .page-link:focus {
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

/* Styles for the sticky table header */
.table-responsive {
  position: relative;
}

.sticky-top {
  position: sticky;
  top: 0;
  z-index: 0;
}

/* Ensure the pagination container stays visible */
.pagination-container {
  position: sticky;
  bottom: 0;
  background-color: #fbfbfb;
  z-index: 1;
  margin-top: 10px;
  box-shadow: 0 -2px 5px rgba(0, 0, 0, 0.05);
}

