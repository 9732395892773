
.hero-image-airport-transfer {
    /* background: url("./images/airport-transfer-wide.png"); */
    background-position: center !important;
    background-repeat: no-repeat !important;
    background-size: cover !important;
    height: 65vh !important;
  }
  
  @media (max-width: 991px) and (min-width: 576px) {
    .hero-image-airport-transfer {
      /* background: url("./images/airport-transfer-wide.png"); */
      background-position: 75% !important;
      background-repeat: no-repeat !important;
      background-size: cover !important;
      height: 90vh !important;
    }
  }
  
  @media (max-width: 576px) {
    .hero-image-airport-transfer {
      /* background: url("./images/airport-transfer-wide-mob.png"); */
      background-position: 75% !important;
      background-repeat: no-repeat !important;
      background-size: cover !important;
      height: 90vh !important;
    }
  }